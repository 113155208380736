@charset "UTF-8";
/* Animations */
/* Z Index*/
/* Colors */
/*$theme-welding:                     #E29030 !default;
$theme-it:                          #B863BF !default;
$theme-other:                       #9785F2 !default;
$theme-transport:                   #5B8AD9 !default;
$theme-building:                    #5bC0D9 !default;
$theme-thegreen:                    #82bf63 !default;
$theme-service:                     darken(#F2CE61, 12%) !default;
$theme-public:                      #F2666D !default;
$theme-business:                    #66BFF2 !default;*/
/* Aspect ration padding for images and boxes that needs to keep a specific aspect*/
/* Scaffolding*/
/* Fonts */
@font-face {
  font-family: "kicons";
  src: url("fonts/kicons.ttf?b6td1u12345") format("truetype"), url("fonts/kicons.woff?b6td1u12345") format("woff"), url("fonts/kicons.svg?b6td1u12345#opwicons") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="kicon-"], [class*=" kicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "kicons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* all icons have been loaded. Show them. */
a:after,
li:before,
.link:before,
.kicon:before,
.nav__link:after,
.block__link:after,
.article__link:after,
.article__image:before,
.stamp--date.end:before,
.accordion__header:after,
.slick-dots:before,
.slick-prev:before,
.slick-next:before {
  opacity: 1 !important; }

.kicon-arrow-next:before {
  content: ""; }

.kicon-calendar:before {
  content: ""; }

.kicon-contract:before {
  content: ""; }

.kicon-deadline:before {
  content: ""; }

.kicon-gps:before {
  content: ""; }

.kicon-handshake:before {
  content: ""; }

.kicon-house:before {
  content: ""; }

.kicon-information:before {
  content: ""; }

.kicon-key-login:before {
  content: ""; }

.kicon-location:before {
  content: ""; }

.kicon-lock:before {
  content: ""; }

.kicon-money:before {
  content: ""; }

.kicon-obs:before {
  content: ""; }

.kicon-opbygning:before {
  content: ""; }

.kicon-overgang-pil:before {
  content: ""; }

.kicon-place:before {
  content: ""; }

.kicon-svendebrev:before {
  content: ""; }

.kicon-checkmark:before {
  content: ""; }

.kicon-close:before {
  content: ""; }

.kicon-cog:before {
  content: ""; }

.kicon-line-double:before {
  content: ""; }

.kicon-list:before {
  content: ""; }

.kicon-minus:before {
  content: ""; }

.kicon-plus:before {
  content: ""; }

.kicon-search-alt:before {
  content: ""; }

.kicon-alarm-clock:before {
  content: ""; }

.kicon-ambulance:before {
  content: ""; }

.kicon-angellist:before {
  content: ""; }

.kicon-arrow-left1:before {
  content: ""; }

.kicon-arrow-right1:before {
  content: ""; }

.kicon-brush:before {
  content: ""; }

.kicon-calculator:before {
  content: ""; }

.kicon-customer:before {
  content: ""; }

.kicon-domain:before {
  content: ""; }

.kicon-emoji-smile:before {
  content: ""; }

.kicon-envelope1:before {
  content: ""; }

.kicon-graduation:before {
  content: ""; }

.kicon-grow:before {
  content: ""; }

.kicon-help:before {
  content: ""; }

.kicon-highlight:before {
  content: ""; }

.kicon-keyword-research:before {
  content: ""; }

.kicon-magnifier1:before {
  content: ""; }

.kicon-medall-alt:before {
  content: ""; }

.kicon-more:before {
  content: ""; }

.kicon-network:before {
  content: ""; }

.kicon-paint-roller:before {
  content: ""; }

.kicon-paperclip:before {
  content: ""; }

.kicon-pencil:before {
  content: ""; }

.kicon-pencil-alt:before {
  content: ""; }

.kicon-phone1:before {
  content: ""; }

.kicon-pizza:before {
  content: ""; }

.kicon-plane:before {
  content: ""; }

.kicon-question-circle:before {
  content: ""; }

.kicon-restaurant:before {
  content: ""; }

.kicon-road:before {
  content: ""; }

.kicon-ruler-pencil:before {
  content: ""; }

.kicon-snapchat:before {
  content: ""; }

.kicon-train-alt:before {
  content: ""; }

.kicon-wheelchair:before {
  content: ""; }

.kicon-instagram:before {
  content: ""; }

.kicon-linkedin:before {
  content: ""; }

.kicon-facebook:before {
  content: ""; }

.kicon-youtube:before {
  content: ""; }

.kicon-arrow-up:before {
  content: ""; }

.kicon-up:before {
  content: ""; }

.kicon-arrow-down:before {
  content: ""; }

.kicon-down1:before {
  content: ""; }

.kicon-arrow-right:before {
  content: ""; }

.kicon-right:before {
  content: ""; }

.kicon-arrow-left:before {
  content: ""; }

.kicon-left1:before {
  content: ""; }

.kicon-chevron-down:before {
  content: ""; }

.kicon-down:before {
  content: ""; }

.kicon-chevron-left:before {
  content: ""; }

.kicon-left:before {
  content: ""; }

.kicon-chevron-right:before {
  content: ""; }

.kicon-right1:before {
  content: ""; }

.kicon-chevron-up:before {
  content: ""; }

.kicon-up1:before {
  content: ""; }

.kicon-telephone:before {
  content: ""; }

.kicon-phone:before {
  content: ""; }

.kicon-call:before {
  content: ""; }

.kicon-contact:before {
  content: ""; }

.kicon-magnifier:before {
  content: ""; }

.kicon-search:before {
  content: ""; }

.kicon-cross:before {
  content: ""; }

.kicon-cancel:before {
  content: ""; }

.kicon-envelope:before {
  content: ""; }

.kicon-mail:before {
  content: ""; }

.kicon-letter:before {
  content: ""; }

.kicon-email:before {
  content: ""; }
