﻿@import "../../_custom-variables.scss";

@font-face {
    font-family: $icons-font-family;
    src: 
        url('fonts/kicons.ttf?b6td1u12345') format('truetype'), 
        url('fonts/kicons.woff?b6td1u12345') format('woff'), 
        url('fonts/kicons.svg?b6td1u12345#opwicons') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="kicon-"], [class*=" kicon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: $icons-font-family !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* all icons have been loaded. Show them. */
a:after,
li:before,
.link:before,
.kicon:before,
.nav__link:after,
.block__link:after,
.article__link:after,
.article__image:before,
.stamp--date.end:before,
.accordion__header:after,
.slick-dots:before,
.slick-prev:before,
.slick-next:before {
    opacity: 1 !important;
}

.kicon-arrow-next {
    &:before {
        content: $kicon-arrow-next;
    }
}

.kicon-calendar {
    &:before {
        content: $kicon-calendar;
    }
}

.kicon-contract {
    &:before {
        content: $kicon-contract;
    }
}

.kicon-deadline {
    &:before {
        content: $kicon-deadline;
    }
}

.kicon-gps {
    &:before {
        content: $kicon-gps;
    }
}

.kicon-handshake {
    &:before {
        content: $kicon-handshake;
    }
}

.kicon-house {
    &:before {
        content: $kicon-house;
    }
}

.kicon-information {
    &:before {
        content: $kicon-information;
    }
}

.kicon-key-login {
    &:before {
        content: $kicon-key-login;
    }
}

.kicon-location {
    &:before {
        content: $kicon-location;
    }
}

.kicon-lock {
    &:before {
        content: $kicon-lock;
    }
}

.kicon-money {
    &:before {
        content: $kicon-money;
    }
}

.kicon-obs {
    &:before {
        content: $kicon-obs;
    }
}

.kicon-opbygning {
    &:before {
        content: $kicon-opbygning;
    }
}

.kicon-overgang-pil {
    &:before {
        content: $kicon-overgang-pil;
    }
}

.kicon-place {
    &:before {
        content: $kicon-place;
    }
}

.kicon-svendebrev {
    &:before {
        content: $kicon-svendebrev;
    }
}

.kicon-checkmark {
    &:before {
        content: $kicon-checkmark;
    }
}

.kicon-close {
    &:before {
        content: $kicon-close;
    }
}

.kicon-cog {
    &:before {
        content: $kicon-cog;
    }
}

.kicon-line-double {
    &:before {
        content: $kicon-line-double;
    }
}

.kicon-list {
    &:before {
        content: $kicon-list;
    }
}

.kicon-minus {
    &:before {
        content: $kicon-minus;
    }
}

.kicon-plus {
    &:before {
        content: $kicon-plus;
    }
}

.kicon-search-alt {
    &:before {
        content: $kicon-search-alt;
    }
}

.kicon-alarm-clock {
    &:before {
        content: $kicon-alarm-clock;
    }
}

.kicon-ambulance {
    &:before {
        content: $kicon-ambulance;
    }
}

.kicon-angellist {
    &:before {
        content: $kicon-angellist;
    }
}

.kicon-arrow-left1 {
    &:before {
        content: $kicon-arrow-left1;
    }
}

.kicon-arrow-right1 {
    &:before {
        content: $kicon-arrow-right1;
    }
}

.kicon-brush {
    &:before {
        content: $kicon-brush;
    }
}

.kicon-calculator {
    &:before {
        content: $kicon-calculator;
    }
}

.kicon-customer {
    &:before {
        content: $kicon-customer;
    }
}

.kicon-domain {
    &:before {
        content: $kicon-domain;
    }
}

.kicon-emoji-smile {
    &:before {
        content: $kicon-emoji-smile;
    }
}

.kicon-envelope1 {
    &:before {
        content: $kicon-envelope1;
    }
}

.kicon-graduation {
    &:before {
        content: $kicon-graduation;
    }
}

.kicon-grow {
    &:before {
        content: $kicon-grow;
    }
}

.kicon-help {
    &:before {
        content: $kicon-help;
    }
}

.kicon-highlight {
    &:before {
        content: $kicon-highlight;
    }
}

.kicon-keyword-research {
    &:before {
        content: $kicon-keyword-research;
    }
}

.kicon-magnifier1 {
    &:before {
        content: $kicon-magnifier1;
    }
}

.kicon-medall-alt {
    &:before {
        content: $kicon-medall-alt;
    }
}

.kicon-more {
    &:before {
        content: $kicon-more;
    }
}

.kicon-network {
    &:before {
        content: $kicon-network;
    }
}

.kicon-paint-roller {
    &:before {
        content: $kicon-paint-roller;
    }
}

.kicon-paperclip {
    &:before {
        content: $kicon-paperclip;
    }
}

.kicon-pencil {
    &:before {
        content: $kicon-pencil;
    }
}

.kicon-pencil-alt {
    &:before {
        content: $kicon-pencil-alt;
    }
}

.kicon-phone1 {
    &:before {
        content: $kicon-phone1;
    }
}

.kicon-pizza {
    &:before {
        content: $kicon-pizza;
    }
}

.kicon-plane {
    &:before {
        content: $kicon-plane;
    }
}

.kicon-question-circle {
    &:before {
        content: $kicon-question-circle;
    }
}

.kicon-restaurant {
    &:before {
        content: $kicon-restaurant;
    }
}

.kicon-road {
    &:before {
        content: $kicon-road;
    }
}

.kicon-ruler-pencil {
    &:before {
        content: $kicon-ruler-pencil;
    }
}

.kicon-snapchat {
    &:before {
        content: $kicon-snapchat;
    }
}

.kicon-train-alt {
    &:before {
        content: $kicon-train-alt;
    }
}

.kicon-wheelchair {
    &:before {
        content: $kicon-wheelchair;
    }
}

.kicon-instagram {
    &:before {
        content: $kicon-instagram;
    }
}

.kicon-linkedin {
    &:before {
        content: $kicon-linkedin;
    }
}

.kicon-facebook {
    &:before {
        content: $kicon-facebook;
    }
}

.kicon-youtube {
    &:before {
        content: $kicon-youtube;
    }
}

.kicon-arrow-up {
    &:before {
        content: $kicon-arrow-up;
    }
}

.kicon-up {
    &:before {
        content: $kicon-up;
    }
}

.kicon-arrow-down {
    &:before {
        content: $kicon-arrow-down;
    }
}

.kicon-down1 {
    &:before {
        content: $kicon-down1;
    }
}

.kicon-arrow-right {
    &:before {
        content: $kicon-arrow-right;
    }
}

.kicon-right {
    &:before {
        content: $kicon-right;
    }
}

.kicon-arrow-left {
    &:before {
        content: $kicon-arrow-left;
    }
}

.kicon-left1 {
    &:before {
        content: $kicon-left1;
    }
}

.kicon-chevron-down {
    &:before {
        content: $kicon-chevron-down;
    }
}

.kicon-down {
    &:before {
        content: $kicon-down;
    }
}

.kicon-chevron-left {
    &:before {
        content: $kicon-chevron-left;
    }
}

.kicon-left {
    &:before {
        content: $kicon-left;
    }
}

.kicon-chevron-right {
    &:before {
        content: $kicon-chevron-right;
    }
}

.kicon-right1 {
    &:before {
        content: $kicon-right1;
    }
}

.kicon-chevron-up {
    &:before {
        content: $kicon-chevron-up;
    }
}

.kicon-up1 {
    &:before {
        content: $kicon-up1;
    }
}

.kicon-telephone {
    &:before {
        content: $kicon-telephone;
    }
}

.kicon-phone {
    &:before {
        content: $kicon-phone;
    }
}

.kicon-call {
    &:before {
        content: $kicon-call;
    }
}

.kicon-contact {
    &:before {
        content: $kicon-contact;
    }
}

.kicon-magnifier {
    &:before {
        content: $kicon-magnifier;
    }
}

.kicon-search {
    &:before {
        content: $kicon-search;
    }
}

.kicon-cross {
    &:before {
        content: $kicon-cross;
    }
}

.kicon-cancel {
    &:before {
        content: $kicon-cancel;
    }
}

.kicon-envelope {
    &:before {
        content: $kicon-envelope;
    }
}

.kicon-mail {
    &:before {
        content: $kicon-mail;
    }
}

.kicon-letter {
    &:before {
        content: $kicon-letter;
    }
}

.kicon-email {
    &:before {
        content: $kicon-email;
    }
}

